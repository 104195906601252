import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];

  disable() {
    console.log("disable");
    this.submitTarget.disabled = true;
  }

  enable() {
    console.log("enable");
    this.submitTarget.disabled = false;
  }
}
