import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "toggleable"];
  static values = {
    match: String,
    hide: { type: Boolean, default: false },
    context: { type: String, default: "" },
  };

  toggle(event) {
    let inputValue = this.inputTarget.value;
    if (this.inputTarget.type == "checkbox") {
      inputValue = this.inputTarget.checked ? "true" : "false";
    }

    if (inputValue == this.matchValue) {
      this.toggleableTargets.forEach((target) => {
        if (target.dataset.formToggleContext == this.contextValue) {
          if (this.hideValue) {
            target.classList.add("hidden");
          } else {
            target.classList.remove("hidden");
          }
        }
      });
    } else {
      this.toggleableTargets.forEach((target) => {
        if (target.dataset.formToggleContext == this.contextValue) {
          if (this.hideValue) {
            target.classList.remove("hidden");
          } else {
            target.classList.add("hidden");
          }
        }
      });
    }
  }
}
