/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;
window.Turbo = Turbo;

require("@rails/activestorage").start();
import "@rails/actiontext";

// ActionCable Channels
import "./channels";

// Stimulus controllers
import "./controllers";

// Register each controller with Stimulus
// Jumpstart Pro & other Functionality
import "./src/**/*";
require("local-time").start();

// Start Rails UJS
Rails.start();
// console.log("Application.....");

import { application } from "./controllers/application";

// Register each controller with Stimulus
// import controllers from "./**/*_controller.js";
// controllers.forEach((controller) => {
//   console.log("Register: ", controller.name);
//   application.register(controller.name, controller.module.default);
// });

import {
  Dropdown,
  Modal,
  Tabs,
  Popover,
  Toggle,
  Slideover,
} from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("tabs", Tabs);
application.register("popover", Popover);
application.register("toggle", Toggle);
application.register("slideover", Slideover);

import Flatpickr from "stimulus-flatpickr";
application.register("flatpickr", Flatpickr);

import Carousel from "stimulus-carousel";
application.register("carousel", Carousel);

import NestedForm from "stimulus-rails-nested-form";
application.register("nested-form", NestedForm);

import Reveal from "stimulus-reveal-controller";
application.register("reveal", Reveal);

import Autosave from "stimulus-rails-autosave";
application.register("autosave", Autosave);

import AutoSubmit from "@stimulus-components/auto-submit";
application.register("auto-submit", AutoSubmit);
